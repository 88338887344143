/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e575f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h1 {
  text-transform: uppercase;
}

.mb-negativa {
  margin-bottom: -3rem;
}

.cards-icones,
.cards-images {
  border-radius: 10px;
  box-shadow   : 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  height       : 200px;
  transition   : 300ms all ease;
}

.cards-icones:hover,
.cards-images:hover {
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.7);
  transition: 300ms all ease;
}

.cards-images {
  border-radius: 10px;
  box-shadow   : 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  height       : 230px;
  transition   : 300ms all ease;
}

.icone-card-images {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.descricao-icone-card {
  font-size: 1.2rem;
}

.bg-fixed {
  position             : relative;
  background           : url("../../img/camera-bg.jpg"), linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-repeat    : no-repeat;
  background-size      : cover;
  height               : 300px;
  width                : 100%;
}

.bg-fixed a {
  position       : absolute;
  top            : 50%;
  left           : 50%;
  transform      : translate(-50%, -50%);
  color          : #fff;
  text-decoration: none;
  border-bottom  : 10px solid transparent;
  transition     : 300ms all ease;
}

.bg-fixed a:hover {
  border-bottom: 10px solid #fff;
  transition   : 300ms all ease;
}

.titulo-bg-fixed {
  font-size: 4.5rem;
}

.items-produtos {
  color      : #333;
  font-size  : 1.5rem;
  font-weight: bold;
}

.items-produtos:hover {
  color          : #888;
  text-decoration: none;
}

.bloco-contato {
  background: #e9ecef;
  padding: 10px;
  border-radius: 5px;
}

.svg-contato {
  width: 25px;
  height: 25px;
  fill: #333;
}

.mapa-contato {
  height: 200px;
  width: 100%;
  border: 0;
}

.thumb-produto {
  border-radius: 10px;
  box-shadow   : 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  transition: 300ms all ease;
}

.thumb-produto:hover {
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.7);
  transition: 300ms all ease;
}

.thumb-produto-imagem {
  border-radius: 10px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  transition: 300ms all ease;
}

.thumb-produto:hover .thumb-produto-imagem {
  border-radius: 50px;
  transform: scale(1.10);
  transition: 300ms all ease;
}

.thumb-produto-titulo {
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 100px;
  background: linear-gradient(90deg, #eee, #ddd);
  transition: 300ms all ease;
}

.thumb-produto:hover .thumb-produto-titulo {
  background: linear-gradient(90deg, #ddd, #eee);
  transition: 300ms all ease;
}

.thumb-titulo-produto {
  font-size: 26px;
}

.thumb-tabela {
  border-radius: 10px;
  box-shadow   : 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  transition: 300ms all ease;
}

.thumb-tabela:hover {
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.7);
  transition: 300ms all ease;
}

.thumb-tabela-imagem {
  border-radius: 10px;
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.table-custom {
  font-size: .8rem;
  text-align: center;
}

.table-custom tr:hover {
  background: #eee;
  transition: 250ms all ease;
}

.table-custom td,
.table-custom th {
  padding: .5rem .2rem;
}

.svg-download {
  width: 25px;
  height: 25px;
  fill: #dc3545;
} 